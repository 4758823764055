div.navbar ul {
margin-right: 30px;
}

div.navbar ul li {
  display: inline;
  margin-left:10px;
  padding: 4px;
}

li {
  display: inline;
}

.button {
  border-radius: 5px;
  padding: 8px;
}

li:hover {
  color:black;
}

.navbar li:hover, .footer-button:hover{
  background-color: rgba(137, 199, 130, 0.8);
  color: black;
  box-shadow: 2px 2px 15px white, -2px -2px 15px white, 2px -2px 15px white, -2px 2px 15px white;
  border-radius: 5px;
}
li.tool-card:hover{
  color: rgba(137, 199, 130, 0.8);
}

.color-button{
  border-radius: 5px;
  padding: 10px;
  background-color: #bff3bb;
  color: black;
}
.color-button:hover{
  
  box-shadow: 2px 2px 15px rgba(137, 199, 130, 0.8), -2px -2px 15px rgba(137, 199, 130, 0.8), 2px -2px 15px rgba(137, 199, 130, 0.8), -2px 2px 15px rgba(137, 199, 130, 0.8);
  color:black
}

#logo {
  height: 60px;
  padding-left: 20px;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.navbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 10;
  background-image: linear-gradient( black , #89c782 ) ;
  color: white;
  font-weight: 700;
}

.project-content {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
}

.big-logo {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

#developer{
  padding-top: 40px;
  padding-bottom: 30px;
}

.developer-container {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 20px;
  align-items: center;
}

.developer-h2{
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.recognition-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  
}

.developer-content h2{
  margin-top: 0;
}

.developer-content {
  line-height: 35px;
}

#footer {
  background-image: linear-gradient(#89c782  , black  );
  height: 350px;
}

.footer-container {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 0;
}

.footer-img {
  margin-top: 20px;
}

.footer-img, .footer-button-ul {
  margin-left: auto;
  margin-right: auto;
  font-weight: 800;
  color: black;
  font-size: 30px;
}

.contact-button-ul {
  margin-left: auto;
  margin-right: auto;
  font-weight: 800;
  color: black;
  font-size: 30px;
  padding-left: 0;
  text-align: center;
  margin-top: 0;
  margin-bottom: 100px;
}

.color-button-ul{
  margin-left: auto;
  margin-right: auto;
  font-weight: 800;
  color: black;
  font-size: 30px;
  padding-left: 0;
  text-align: center;
}

div.footer-container ul {
  padding-left: 0;
  
}

.footer-button {
  background-color: white;
}

.footer-icons-ul {
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  color: white;
}

.footer-icons-ul li {
  padding-right: 15px;
  padding-left: 15px;
}

.footer-icons-ul li:hover {
  color: rgba(137, 199, 130, 0.8)
}

.project-samples{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 50px;

}

.sample {
  padding: 20px;
  border: 1px solid lightgrey;
  margin-left: 5px;
  margin-right: 5px;
}

.samples-p {
  padding-top: 30px;
}

.devIcons{
  margin-top: 40px;
  width: 60px
}

.tool-card{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  color: black;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 40px;
}

.icons-ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

h2{
  padding-top: 25px;
}

.video {
  padding-top: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.project-content-video {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
}

a.nucamp-grad-link{
  color: #6bc561;

}
a.nucamp-grad-link:hover{
  text-decoration: underline;
}

.darya-logo {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}


.cls-1{
  fill:#fff;
  fill-rule:evenodd;
}
.cls-1:hover{
  fill:rgba(137, 199, 130, 0.8);
}

.logo-for-hover{
  height: 45px;
  margin-bottom: -10px;
  margin-right:0px;
}

.logo-for-hover-mail{
  height: 40px;
  margin-bottom: -8px;
}

.logo-for-hover-mail:hover{
  fill: rgba(137, 199, 130, 0.8);
}

.about-small-screen{
  display: none;
}

@media only screen and (max-width: 768px) {

  #footer {
    width: 100%;
  }
  
  .project-content {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .project-content-video {
    width: 300px;
  }

  iframe {
    width: 300px;
    height: 160px;
  }

  .developer-h2{
    width: 290px;
  }

  .developer-container{
    width: 300px;
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    line-height: 65px;
    margin-top: 140px;
  }

  .footer-container {
    width: 100%;
    
  }

  .big-logo {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  
  .big-logo-img {
    height: 160px ;
  }

  .project-samples{
    display: flex;
    flex-wrap: wrap;
    
  }

  .tool-card{
    margin-left: 10px;
    margin-right: 10px;
  }

  .navbar {
    width:100%;
  }

  .about-large-screen{
    display: none;
  }

  .about-small-screen{
    display: inline-block;
  }
  #logo{
    height: 30px;
    padding: 0;
  }

  .navbar {
    justify-content: left;
  }

  .navbar-links{
    padding: 0;
  }

  .recognition-image{
    height: 200px;
  }

  .recognition-content{
    display: flex;
    flex-wrap: wrap;
  }

  .footer-icons {
    margin-left: -5px;
    margin-right: -5px;
  }

  .sample {
    margin-bottom: 10px;
  }

  .project-samples{
    padding-bottom: 0px;
  }
}